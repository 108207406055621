@import "../../../ui/styleUtils/index.scss";
@import "../../../variables.scss";

.searchControls {
  padding: $sizeL $sizeXxl * 2 0;
}

@media screen and (max-width: $breakpointContentIsCramped) {
  .searchControls {
    padding: $sizeL $sizeXxl 0;
  }
}

.kbLogo {
  width: 80px;
  position: absolute;
  margin-top: -28px;
}

.kbTitle {
  margin-left: 73px;
}

.filtersBar {
  height: 34px;
}
